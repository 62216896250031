import React from 'react';

const SettingsWheel = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" id="i-set" viewBox="0 0 1027 1024">
      <g fill="var(--anchor-color)">
        <path d="M1005.37728 614.4l-115.2-64c0-12.8 0-25.6 0-38.4s0-25.6 0-38.4l115.2-64C1024.57728 403.2 1030.97728 384 1018.17728 364.8l-128-224c-6.4-12.8-25.6-19.2-44.8-12.8l-115.2 64c-19.2-12.8-38.4-25.6-64-38.4l0-128C672.57728 12.8 659.77728 0 640.57728 0L384.57728 0C365.37728 0 352.57728 12.8 352.57728 32l0 128c-19.2 12.8-44.8 19.2-64 38.4l-115.2-64C160.57728 121.6 141.37728 128 134.97728 147.2l-128 224C-5.82272 384 0.57728 403.2 13.37728 409.6l115.2 64C128.57728 486.4 128.57728 499.2 128.57728 512s0 25.6 0 38.4l-115.2 64C0.57728 620.8-5.82272 640 6.97728 659.2l128 224c6.4 12.8 25.6 19.2 44.8 12.8l115.2-64c19.2 12.8 38.4 25.6 64 38.4l0 128C352.57728 1011.2 365.37728 1024 384.57728 1024l256 0c19.2 0 32-12.8 32-32l0-128c19.2-12.8 44.8-19.2 64-38.4l115.2 64c12.8 6.4 32 6.4 44.8-12.8l128-224C1030.97728 640 1024.57728 620.8 1005.37728 614.4zM838.97728 774.4l-115.2-70.4c-38.4 44.8-89.6 70.4-147.2 83.2l0 134.4L448.57728 921.6l0-134.4c-57.6-12.8-108.8-44.8-147.2-83.2l-115.2 70.4-64-108.8 115.2-70.4C230.97728 569.6 224.57728 544 224.57728 512s6.4-57.6 12.8-83.2L122.17728 358.4l64-108.8 115.2 70.4C339.77728 275.2 390.97728 243.2 448.57728 230.4L448.57728 96l128 0 0 134.4c57.6 12.8 108.8 44.8 147.2 83.2l115.2-70.4 64 108.8-115.2 70.4c6.4 25.6 12.8 57.6 12.8 83.2s-6.4 57.6-12.8 83.2l115.2 70.4L838.97728 774.4z"> </path> <path d="M512.57728 320C403.77728 320 320.57728 403.2 320.57728 512s83.2 192 192 192 192-83.2 192-192S621.37728 320 512.57728 320zM512.57728 640c-70.4 0-128-57.6-128-128s57.6-128 128-128 128 57.6 128 128S582.97728 640 512.57728 640z"> </path>
      </g>
    </svg>
  );
}
export default SettingsWheel;